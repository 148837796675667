<template>
<div class="main">
  <Tree service="/sys/sysOrganization/dept" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      selectRow: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [
            { code: "create", name: "新增", status: "primary" },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'name',
            title: '岗位名称',
            minWidth: 160,
            editRender: {
              name: 'input',
              props: {
                maxlength: 24,
              }
            }
          },
          {
            field: "orderCode",
            title: "排序",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 3,
              }
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   minWidth: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row);
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            },
          },
        ],
        treeConfig: {
          children: "children",
          accordion: false,
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectParent",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                },
              }
            },
          }, {
            field: "name",
            title: "岗位名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 30,
                placeholder: "请输入岗位名称",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 3,
                type: "integer",
                placeholder: "请输入排序号",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入部门名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          code: [
            { required: true, message: "请输入部门代码" },
            { min: 2, max: 24, message: "长度在 2 到 24 个字符" },
          ],
          orderCode: [{ required: true, message: "请输入名称" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          name: "",
          code: "",
          orderCode: "0",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "岗位姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入岗位姓名",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {},
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.formOptions.data['parentName'] = node.name;
      let { xGrid } = this.$refs;
      xGrid.searchOptions.data["parentId"] = node.id;
      xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
